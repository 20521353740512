<template>
  <div
    :key="item.studentOrder || ''"
    v-ref="parseMathML"
    class="exam-item"
    :class="{ '--disabled': disabled }"
  >
    <div class="exam-item__header">
      <h4>Questão {{ item.studentOrder || '' }}</h4>

      <ExamTimer
        v-if="mq_m && hasDeadline"
        @end-time="endTime()"
        @open-timer="$emit('open-timer')"
      />
    </div>

    <div
      ref="item"
      v-viewer="{
        toolbar: false,
        navbar: false,
        title: () => `<h5>Questão ${item.studentOrder || ''}</h5>`,
      }"
      class="exam-item__statement"
      v-html="formattedItem"
    />

    <div class="exam-item__options">
      <ExamItemOption
        v-for="(option, index) in options"
        :key="index"
        :body="option"
        :class="{ '--disabled': loading }"
        :discarded-option="isDiscardedOptions(index)"
        :has-discarded-button="hasDiscartedButton"
        :letter="getOptionByOrder(index)"
        :order="index"
        :selected="item.answer && item.answer.markedOption === index"
        :selected-order="selectedOrder"
        @answer="answer($event)"
        @discard="discardOption($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import ExamItemOption from '@/components/ExamItemOption'
import overrideStyles from '@/mixins/overrideStyles'
import ExamTimer from '@/components/ExamTimer'
import mediaQueries from '@/mixins/mediaQueries'
import mathTypeViewer from '@/mixins/mathTypeViewer'

export default {
  name: 'ExamItem',

  components: {
    ExamItemOption,
    ExamTimer,
  },

  mixins: [overrideStyles, mediaQueries, mathTypeViewer],

  beforeRouteUpdate(to, from, next) {
    this.setItem(parseInt(to.params.itemOrder, 10))
    next()
  },

  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      selectedOrder: null,
      loading: false,
    }
  },

  computed: {
    ...mapGetters([
      'exam',
      'item',
      'applicationResponse',
      'hasDeadline',
    ]),
    formattedItem() {
      return this.$mq.includes('small')
        ? ((this.item || {}).formattedWording || '').replace(/&nbsp;/gi, ' ')
        : (this.item || {}).formattedWording || ''
    },
    options() {
      return (this.item || {}).formattedOptions
        ? JSON.parse(this.item.formattedOptions)
        : []
    },
    hasDiscartedButton() {
      return this.exam.dateStatus === 'APPLICATION_STARTED'
    },
  },
  created() {
    this.loadMathTypeViewer()
  },
  methods: {
    ...mapActions(['setItem', 'answerItem', 'discardOptions']),

    async answer(optionOrder) {
      try {
        if (this.loading || this.disabled) {
          return
        }

        this.selectedOrder = optionOrder
        this.loading = true

        const { answer } = this.item
        const markedOption = answer.markedOption !== optionOrder
          ? optionOrder
          : -1

        await this.answerItem({
          markedOption,
          applicationResponseId: this.applicationResponse.id,
        })
      } catch (error) {
        this.$toasted.global.error({
          message: 'Erro ao tentar responder.',
          errorCode: error.response ? error.response.status : '',
        })
      } finally {
        this.selectedOrder = null
        this.loading = false
      }
    },

    async discardOption(optionOrder) {
      if (this.loading || this.disabled) {
        return
      }

      this.loading = true

      try {
        await this.discardOptions({
          optionOrder,
          applicationResponseId: this.applicationResponse.id,
        })
      } finally {
        this.loading = false
      }
    },

    getOptionByOrder(optionOrder) {
      const options = ['A', 'B', 'C', 'D', 'E']

      return options[optionOrder]
    },

    isDiscardedOptions(index) {
      if (!this.item.discardOption) {
        return false
      }

      return this.item.discardOption.discardedOptions.includes(index)
    },

    endTime() {
      this.$emit('end-time')
    },
  },

}
</script>

<style lang="sass">

.exam-item
  box-sizing: border-box
  color: $color-ink
  position: relative
  +anim(200ms)

  +mq-s
    max-width: 320px

  &.--disabled
    pointer-events: none

  &__header
    display: flex
    align-items: center
    margin-bottom: 16px
    position: sticky
    top: 0
    background: #fafafa
    padding: 4px 0
    z-index: 10

    +mq-m
      justify-content: space-between

  &__statement

    img, table, td
      object-fit: contain
      object-position: center center
      max-width: 100% !important
      cursor: zoom-in

      +mq-s
        width: auto !important
        height: auto !important

    p, span
      letter-spacing: 0.64px !important
      line-height: 1.4
      -webkit-user-select: none
      -moz-user-select: none
      user-select: none
      -webkit-margin-before: 1em
      -webkit-margin-after: 1em
      -webkit-margin-start: 0px
      -webkit-margin-end: 0px

      +mq-s
        text-align: left !important
        letter-spacing: .16px !important
        margin-left: 0 !important
        margin-right: 0 !important

  &__options
    margin-top: 48px

    +mq-s
      margin-top: 24px

  &:hover
    .exam-item__toolbar
      +anim(200ms)
      opacity: 1
      transform: scale(1)
      transform-origin: top right
</style>
